<template>
  <v-card>
    <v-toolbar color="color1 color1Text--text">
      <v-toolbar-title>Ratings Earned</v-toolbar-title>
    </v-toolbar>
    <v-card-text v-for="r in ratings" :key="r.systemId" class="pa-0">
      <div v-if="ratings.length > 1">{{r.system}}</div>
      <v-data-table
        :headers="headers"
        :items="r.ratings"
        class="elevation-1"
        :class="{'no-head': $vuetify.breakpoint.xsOnly}"
        :options.sync="page"
        item-key="id"
        :search="search"
        color="color3"
        disable-sort
      >
        <template v-slot:[`item.displayed2`]="{ item }">
          {{item.id}}
        </template>
        <template v-slot:[`item.displayed`]="{ item }">
          <v-btn icon v-if="item.id !== selectedId" @click.stop="preAddSelected(item)">
            <v-icon>far fa-square</v-icon>
          </v-btn>
          <v-btn icon color="success" v-if="item.id === selectedId" @click.stop="preRemoveSelected">
            <v-icon>fas fa-check-square</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.rating`]="{ item }">
          {{item.rating | ratingDisplay}}
        </template>
        <template v-slot:[`item.currentRating`]="{ item }">
          {{item.currentRating | ratingDisplay}}
        </template>
        <template v-slot:[`item.dtEarned`]="{ item }">
          {{item.dtEarned | localDate}}
        </template>
        <template v-slot:[`item.finish`]="{ item }">
          {{item.finish | ordinal}}
        </template>
        <template v-slot:[`item.tournament`]={item}>
          <router-link
            class="color3--text"
            :to="{name: 'tournament-brochure', params: {tournamentId: item.tournamentId}}"
          >
            {{item.tournament}}
          </router-link>
          <tournament-rating-summary-dialog
            :tournamentId="item.tournamentId"
            :infoBtn="true"
            :past="true"
          ></tournament-rating-summary-dialog>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text v-if="false">
      <v-container fluid>
        <v-expand-transition mode="out-in">
          <v-row dense>
            <v-col cols="12" md="6" offset-md="6" class="text-end">
              <v-text-field
                label="Search"
                v-model="search"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-expand-transition>
        <v-row dense>
          <v-col cols="12" v-for="r in ratings" :key="r.systemId">
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <vbl-confirm-dialog
      :ask="confirmOpen"
      :question="q1"
      :loading="loading"
      @confirm="addSelected"
      @deny="confirmOpen = false"
    ></vbl-confirm-dialog>
  </v-card>
</template>

<script>
import TournamentRatingSummaryDialog from '@/components/Ratings/TournamentRatingSummaryDialog'
import { ratingDisplay } from '@/Filters'

export default {
  props: ['player', 'view'],
  data () {
    return {
      page: {},
      search: null,
      confirmOpen: false,
      newSelectedId: 0,
      q1: null,
      loading: false
    }
  },
  computed: {
    ratings () {
      return this.player ? this.player.ratings : []
    },
    headers () {
      return [
        { text: 'Displayed', value: 'displayed', show: (this.view.admin || this.view.vbl) },
        { text: 'Rating', value: 'rating', show: true },
        { text: 'Date Earned', value: 'dtEarned', show: true },
        { text: 'Event', value: 'tournament', show: true },
        { text: 'Division', value: 'division', show: true },
        { text: 'Finish', value: 'finish', show: true },
        { text: 'Current Value', value: 'currentRating', show: true }
      ].filter(f => f.show)
    },
    selectedId () {
      const tag = this.player && this.player.tags.find(f => f.startsWith('rating-'))
      if (tag) {
        const idString = tag.replace('rating-', '')
        return +idString
      }
      return null
    }
  },
  methods: {
    preAddSelected (rating) {
      this.q1 = `Display ${ratingDisplay(rating.currentRating)} as your rating?`
      this.newSelectedId = rating.id
      this.confirmOpen = true
    },
    preRemoveSelected () {
      this.q1 = 'Go back to the default display?'
      this.newSelectedId = 0
      this.confirmOpen = true
    },
    addSelected () {
      this.loading = true
      this.$VBL.ratings.display(this.player.ppId, this.newSelectedId)
        .then(r => {
          this.player.tags = r.data
          this.confirmOpen = false
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false
        })
    }
  },
  components: {
    TournamentRatingSummaryDialog
  }
}
</script>

<style scoped>
.no-head >>> thead {
  display: none;
}
</style>
